import RPGButton from "./button/RPGButton";
import polar_rpg from "../assets/video/Polarrpg.mp4";
import { useEffect } from "react";

export default function PolarRPG() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const RPGVideo = document.getElementById("RPG_video");

      if (RPGVideo.getBoundingClientRect().top < viewportHeight) {
        RPGVideo.classList.add("animate-fadeInRightScroll");
      }

      const RPGPhase = document.getElementById("RPG_phase");

      if (RPGPhase.getBoundingClientRect().top < viewportHeight) {
        RPGPhase.classList.add("animate-fadeInLeftScroll");
      }

      const RPGButton = document.getElementById("RPG_button");

      if (RPGButton.getBoundingClientRect().top < viewportHeight) {
        RPGButton.classList.add("visible");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="flex flex-col lg:flex-row my-20 mx-4 lg:mx-32 items-center">
      <div name="RPG_video" id="RPG_video" className="lg:w-1/2 mx-auto">
        <video
          autoPlay
          loop
          muted
          className="w-full max-w-[500px] h-auto mx-auto"
        >
          <source src={polar_rpg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="text-center mt-10 lg:text-left lg:mt-0 lg:ml-10 lg:w-1/2">
        <div name="RPG_phase" id="RPG_phase">
          <h3 className="font-bold text-white text-3xl">
            A New Adventure Awaits
          </h3>
          <p className="text-slate-500 pb-10 pt-3">
            Embark on an epic journey in our Blockchain Pixel RPG, a
            groundbreaking experience where users can mine rare crystals, train
            loyal dragon companions, and embark on thrilling adventures.
            Experience life in a captivating world beyond your imagination, and
            engage in intense battles with fellow players. Be one of the first
            to dive into this revolutionary RPG experience!
          </p>
        </div>
        <div id="RPG_button" className=" animate-fadeIn">
          <RPGButton />
        </div>
      </div>
    </section>
  );
}
