import { Link } from "react-router-dom";

import polarLogo from "../assets/img/polar-logo.svg";
import polarText from "../assets/img/polarfi-text.svg";

export default function Footer() {
  return (
    <footer className="mt-10 p-6 text-center">
      <div>
        <div className="mx-auto">
          <div>
            <Link className="flex justify-center cursor-pointer" to="/">
              <img className="h-8 w-8 px-1" src={polarLogo} alt="polar Logo" />
              <img className="h-8 w-16 px-1" src={polarText} alt="polar Text" />
            </Link>
          </div>
          <p className="text-white">
            Copyright © 2024 PolarFi. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
