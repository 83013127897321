import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import CommunityButton from "./button/CommunityButton";

import polarLogo from "../assets/img/polar-logo.svg";
import polarText from "../assets/img/polarfi-text.svg";

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHoveredMiniGames, setIsHoveredMiniGames] = useState(false);
  const [isHoveredFortune, setIsHoveredFortune] = useState(false);
  const [isMiniGamesOpen, setIsMiniGamesOpen] = useState(false);
  const [isFortuneOpen, setIsFortuneOpen] = useState(false);

  const location = useLocation();

  const handleMouseEnterMiniGames = () => {
    setIsHoveredMiniGames(true);
  };

  const handleMouseLeaveMiniGames = () => {
    setIsHoveredMiniGames(false);
  };

  const handleMouseEnterFortune = () => {
    setIsHoveredFortune(true);
  };

  const handleMouseLeaveFortune = () => {
    setIsHoveredFortune(false);
  };

  const handleMiniGamesClick = () => {
    setIsMiniGamesOpen(!isMiniGamesOpen);
  };

  const handleFortuneClick = () => {
    setIsFortuneOpen(!isFortuneOpen);
  };

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <nav className="flex justify-between items-center px-10 py-5 relative">
      {!isMenuOpen && (
        <div>
          <Link className="flex cursor-pointer" to="/">
            <img className="h-8 w-8 px-1" src={polarLogo} alt="polar Logo" />
            <img className="h-8 w-16 px-1" src={polarText} alt="polar Text" />
          </Link>
        </div>
      )}

      <div className="hidden md:flex relative">
        <Link className="px-2 text-white hover:text-sky-500" to="/">
          Home
        </Link>
        <div
          className="relative group"
          onMouseEnter={() => handleMouseEnterMiniGames()}
          onMouseLeave={() => handleMouseLeaveMiniGames()}
        >
          <Link className="px-2 text-white hover:text-sky-500" to="/#mini_game">
            Mini Games
          </Link>
          <div
            className={`absolute z-20 bg-background text-white px-2 pb-2 pt-8 rounded-lg shadow-lg ${
              isHoveredMiniGames ? "block" : "hidden"
            } `}
          >
            <a
              className="block hover:text-sky-500"
              href="https://pbbr.polarfi.io/"
            >
              PBBR
            </a>
            <a
              className="block hover:text-sky-500"
              href="https://haregames.polarfi.io/"
            >
              HareGames
            </a>
            <a
              className="block hover:text-sky-500"
              href="https://pff.polarfi.io/"
            >
              PFF
            </a>
          </div>
        </div>

        <div
          className="relative group"
          onMouseEnter={() => handleMouseEnterFortune()}
          onMouseLeave={() => handleMouseLeaveFortune()}
        >
          <Link className="px-2 text-white hover:text-sky-500" to="/#finance">
            Finance
          </Link>
          <div
            className={`absolute z-20 bg-background text-white px-2 pb-2 pt-8 rounded-lg shadow-lg ${
              isHoveredFortune ? "block" : "hidden"
            } `}
          >
            <a
              className="block hover:text-sky-500"
              href="https://fortune.polarfi.io/"
            >
              Fortune
            </a>
          </div>
        </div>
        <Link className="px-2 text-white hover:text-sky-500" to="/#about">
          About
        </Link>
        <a
          className="px-2 text-white hover:text-sky-500"
          href="https://discord.gg/polarfi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support
        </a>
        <Link className="px-2 text-white hover:text-sky-500" to="/">
          Buy $FROST
        </Link>
      </div>

      <div className="hidden md:flex">
        <CommunityButton />
      </div>

      <div className="md:hidden flex items-center">
        <button
          className="absolute top-0 right-0 mt-5 mr-4"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="h-6 w-6 text-white"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              d={
                !isMenuOpen ? "M4 6h16M4 12h16m-7 6h7" : "M6 18L18 6M6 6l12 12"
              }
            />
          </svg>
        </button>
      </div>

      {isMenuOpen && (
        <div className="text-white p-5 bg-background top-0 left-0 h-auto w-full bg-black bg-opacity-0 z-10 md:hidden">
          <Link className="block px-2 py-1 hover:text-sky-500" to="/">
            Home
          </Link>
          <div
            className={`relative group block px-2 py-1 hover:text-sky-500 ${
              isMiniGamesOpen ? "mb-28" : ""
            }`}
          >
            <Link to="/" onClick={() => handleMiniGamesClick()}>
              Mini Games
            </Link>
            <div
              className={`absolute z-20 bg-background text-white px-2 pb-2 pt-6 rounded-lg shadow-lg ${
                isMiniGamesOpen ? "block" : "hidden"
              } `}
            >
              <a
                className="block hover:text-sky-500"
                href="https://pbbr.polarfi.io/"
              >
                PBBR
              </a>
              <a
                className="block hover:text-sky-500"
                href="https://haregames.polarfi.io/"
              >
                HareGames
              </a>
              <a
                className="block hover:text-sky-500"
                href="https://pff.polarfi.io/"
              >
                PFF
              </a>
            </div>
          </div>

          <div
            className={`relative group block px-2 py-1 hover:text-sky-500 ${
              isFortuneOpen ? "mb-16" : ""
            }`}
          >
            <Link to="/" onClick={() => handleFortuneClick()}>
              Finance
            </Link>
            <div
              className={`absolute z-20 bg-background text-white px-2 pb-2 pt-6 rounded-lg shadow-lg ${
                isFortuneOpen ? "block" : "hidden"
              } `}
            >
              <a
                className="block hover:text-sky-500"
                href="https://fortune.polarfi.io/"
              >
                Fortune
              </a>
            </div>
          </div>

          <Link className="block px-2 py-1 hover:text-sky-500" to="/">
            About
          </Link>
          <Link className="block px-2 py-1 hover:text-sky-500" to="/">
            Support
          </Link>
          <Link className="block px-2 py-1 hover:text-sky-500" to="/">
            Buy $FROST
          </Link>
          <div className="mt-10">
            <CommunityButton />
          </div>
        </div>
      )}
    </nav>
  );
}
