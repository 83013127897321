import FrostButton from "./button/FrostButton";
import frost from "../assets/img/frost.png";
import { useEffect } from "react";

export default function Frost() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const frostImage = document.getElementById("frost_image");

      if (frostImage.getBoundingClientRect().top < viewportHeight) {
        frostImage.classList.add("animate-fadeInLeftScroll");
      }

      const frostText = document.getElementById("frost_text");

      if (frostText.getBoundingClientRect().top < viewportHeight) {
        frostText.classList.add("animate-fadeInRightScroll");
      }

      const frostButton = document.getElementById("frost_button");

      if (frostButton.getBoundingClientRect().top < viewportHeight) {
        frostButton.classList.add("visible");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="flex flex-col lg:flex-row my-20 mx-4 lg:mx-32 items-center">
      <div
        name="frost_image"
        id="frost_image"
        className="lg:w-1/2 lg:ml-10 lg:mt-0 mx-auto order-first lg:order-last"
      >
        <img
          src={frost}
          alt="frost"
          className="w-full max-w-[500px] h-auto mx-auto object-cover"
        />
      </div>
      <div className="text-center lg:text-left mt-10 lg:w-1/2 order-last lg:order-first">
        <div name="frost_text" id="frost_text">
          <h3 className="font-bold text-white text-3xl">
            $FROST TOKEN is the Engine that Fuels Everything
          </h3>
          <p className="text-slate-500 pb-10 pt-3">
            $FROST, the heart of PolarFi's ecosystem, is a versatile token set
            to redefine your crypto experience. With applications across our
            diverse platform, $FROST seamlessly facilitates transactions,
            staking, and participation in various features. Join the PolarFi
            community, where $FROST isn't just a token; it's the key to
            unlocking a world of opportunities within our dynamic and
            interconnected ecosystem.
          </p>
        </div>

        <div id="frost_button" className=" animate-fadeIn">
          <FrostButton />
        </div>
      </div>
    </section>
  );
}
