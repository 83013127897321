import NewspolarisButton from "./button/NewspolarisButton";
import polaris from "../assets/img/polaris.png";
import { useEffect } from "react";

export default function Polaris() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const polarisImage = document.getElementById("polaris_image");

      if (polarisImage.getBoundingClientRect().top < viewportHeight) {
        polarisImage.classList.add("animate-fadeInLeftScroll");
      }

      const polarisText = document.getElementById("polaris_text");

      if (polarisText.getBoundingClientRect().top < viewportHeight) {
        polarisText.classList.add("animate-fadeInRightScroll");
      }

      const polarisButton = document.getElementById("polaris_button");

      if (polarisButton.getBoundingClientRect().top < viewportHeight) {
        polarisButton.classList.add("visible");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="flex flex-col lg:flex-row my-20 mx-4 lg:mx-32 items-center">
      <div
        name="polaris_image"
        id="polaris_image"
        className="lg:w-1/2 lg:ml-10 lg:mt-0 mx-auto order-first lg:order-last"
      >
        <img
          src={polaris}
          alt="polaris"
          className="w-full max-w-[500px] h-auto mx-auto object-cover"
        />
      </div>
      <div className="text-center lg:text-left mt-10 lg:w-1/2 order-last lg:order-first">
        <div name="polaris_text" id="polaris_text">
          <h3 className="font-bold text-white text-3xl">
            Now Building the Biggest Triple AAA MOBA Game within Web3
          </h3>
          <p className="text-slate-500 pb-10 pt-3">
            PolarFi's dedicated team is passionately crafting our competitive
            AAA MOBA game, Polaris. As our flagship title, Polaris is poised to
            achieve tremendous success. Stay tuned for an unparalleled gaming
            experience that will redefine the standards in the world of MOBA
            games. Get ready to be part of the gaming revolution with Polaris!
          </p>
        </div>

        <div id="polaris_button" className=" animate-fadeIn">
          <NewspolarisButton />
        </div>
      </div>
    </section>
  );
}
