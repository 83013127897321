import React from "react";
import bg_hero from "../assets/video/bg_hero.mp4";

import CommunityButton from "./button/CommunityButton";

export default function Hero() {
  return (
    <section className="relative h-screen overflow-hidden">
      <video autoPlay muted className="absolute z-0 w-full h-full object-cover">
        <source src={bg_hero} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="relative z-10 flex flex-col items-center justify-center h-screen">
        <div className="p-6 m-4 w-3/4 md:w-1/2 animate-fadeInUp">
          <h1 className="text-white text-4xl xl:text-5xl font-bold text-center">
            Building the Future of Entertainment with Web3
          </h1>
          <p className="text-slate-400 text-sm text-center py-5 animate-fadeInUp">
            Join us on our exciting journey as we navigate through the dynamic
            world of web3, crafting innovative entertainment experiences along
            the way.
          </p>
          <div className="flex justify-center mt-4 animate-fadeInUp">
            <CommunityButton />
          </div>
        </div>
      </div>
    </section>
  );
}
