import stax from "../assets/img/stax.png";
import foxeek from "../assets/img/foxeek.webp";
import prexzert from "../assets/img/prexzert.png";
import julian from "../assets/img/julian.png";
import enor from "../assets/img/enor.png";
import JoinTeam from "./button/JoinTeam";
import { useEffect } from "react";
import { Element } from "react-scroll";

export default function Team() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const teamTitle = document.getElementById("team_title");

      if (teamTitle.getBoundingClientRect().top < viewportHeight) {
        teamTitle.classList.add("animate-fadeInUpScroll");
      }

      const TeamText = document.getElementById("team_text");

      if (TeamText.getBoundingClientRect().top < viewportHeight) {
        TeamText.classList.add("animate-fadeInUpScroll");
      }

      const teamMembers = document.getElementById("team_members");

      if (teamMembers.getBoundingClientRect().top < viewportHeight) {
        teamMembers.classList.add("animate-fadeInUpScroll");
      }

      const teamJoin = document.getElementById("team_join");

      if (teamJoin.getBoundingClientRect().top < viewportHeight) {
        teamJoin.classList.add("animate-fadeInUpScroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="flex flex-col items-center justify-center mx-auto px-4">
      <div className="text-center my-4">
        <Element name="team_title" id="team_title">
          <h3 className="font-bold text-white text-3xl">Meet the Team!</h3>
        </Element>
        <Element name="team_text" id="team_text">
          <p className="text-slate-400 max-w-prose mx-auto my-6">
            Meet the brilliant minds driving PolarFi forward. Our diverse team
            combines expertise in blockchain, finance, innovation and more.
          </p>
        </Element>
      </div>
      <Element
        name="team_members"
        id="team_members"
        className="border mx-5 px-5 py-5 border-white flex flex-wrap justify-center"
      >
        <div className="text-center mx-4 my-4">
          <span className="text-slate-500">Chief Executive Officer(CEO)</span>
          <img
            src={prexzert}
            alt="Stax Logo"
            className="mx-auto w-[225px] h-[225px] max-w-[225px] max-h-[225px] object-cover"
          />
          <span className="text-white text-2xl">Prexzert</span>
        </div>
        <div className="text-center mx-4 my-4">
          <span className="text-slate-500">Chief Technical Officer (CTO)</span>
          <img
            src={foxeek}
            alt="Stax Logo"
            className="mx-auto max-w-[225px] w-[225px] h-[225px] max-h-[225px] object-cover"
          />
          <span className="text-white text-2xl">Foxeek</span>
        </div>
        <div className="text-center mx-4 my-4">
          <span className="text-slate-500">Chief Marketing Officer (CMO)</span>
          <img
            src={stax}
            alt="Stax Logo"
            className="mx-auto max-w-[225px] w-[225px] h-[225px] max-h-[225px] object-cover"
          />
          <span className="text-white text-2xl">Stax</span>
        </div>
        <div className="text-center mx-4 my-4">
          <span className="text-slate-500">Chief Security Officer (CSO)</span>
          <img
            src={julian}
            alt="Stax Logo"
            className="mx-auto max-w-[225px] w-[225px] h-[225px] max-h-[225px] object-cover"
          />
          <span className="text-white text-2xl">Julian</span>
        </div>
        <div className="text-center mx-4 my-4">
          <span className="text-slate-500">Polaris Lead Manager (PLM)</span>
          <img
            src={enor}
            alt="Stax Logo"
            className="mx-auto max-w-[225px] w-[225px] h-[225px] max-h-[225px] object-cover"
          />
          <span className="text-white text-2xl">Enor</span>
        </div>
      </Element>
      <Element name="team_join" id="team_join" className="mt-10 text-center">
        <h3 className="font-bold text-white text-3xl">
          Join us in shifting the world of entertainment
        </h3>
        <p className="text-slate-500 mt-5 pb-2">We are hiring!</p>
        <JoinTeam />
      </Element>
    </section>
  );
}
