export default function Border() {
  return (
    <>
      {" "}
      <svg
        className="mx-auto"
        width="60vw"
        height="9"
        viewBox="0 0 1117 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.994531"
          y1="6.00001"
          x2="1116.99"
          y2="3.96537"
          stroke="#0F6990"
          strokeWidth="6"
        />
      </svg>
    </>
  );
}
