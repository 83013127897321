import React, { useEffect } from "react";
import { Element } from "react-scroll";

import MoreInfo from "./button/MoreInfo";

import logoRed from "../assets/img/logo_red.svg";
import logoPurple from "../assets/img/logo_purple.svg";
import logoGreen from "../assets/img/logo_green.svg";

import icon_heart from "../assets/img/icon_heart.png";
import icon_gift from "../assets/img/icon_gift.png";
import icon_fusee from "../assets/img/icon_fusee.png";

export default function Choice() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const titleChoice = document.getElementById("title_choice");

      if (titleChoice.getBoundingClientRect().top < viewportHeight) {
        titleChoice.classList.add("animate-fadeInUpScroll");
      }

      const textChoice = document.getElementById("text_choice");

      if (textChoice.getBoundingClientRect().top < viewportHeight) {
        textChoice.classList.add("animate-fadeInUpScroll");
      }

      const gameChoice = document.getElementById("game_choice");

      if (gameChoice.getBoundingClientRect().top < viewportHeight) {
        gameChoice.classList.add("animate-fadeInLeftScroll");
      }

      const subscriptionChoice = document.getElementById("subscription_choice");

      if (subscriptionChoice.getBoundingClientRect().top < window.innerHeight) {
        subscriptionChoice.classList.add("animate-fadeInUpScroll");
      }

      const financeChoice = document.getElementById("finance_choice");

      if (financeChoice.getBoundingClientRect().top < window.innerHeight) {
        financeChoice.classList.add("animate-fadeInRightScroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="flex flex-col justify-center items-center" id="about">
      <div className="p-6 m-4 w-3/4 md:w-1/2 text-center">
        <Element name="title_choice" id="title_choice">
          <h2 className="text-white text-4xl font-bold">
            A Whole New Decentralized Entertainment Hub Coming Soon!
          </h2>
        </Element>
        <Element name="text_choice" id="text_choice">
          <p className="text-slate-500 text-sm text-center py-5">
            PolarFi is on a mission to build the largest decentralized
            entertainment hub with the use of blockchain technology. This Hub
            will initially feature 3 core sectors as seen below.
          </p>
        </Element>
      </div>
      <div className="flex flex-wrap justify-center ">
        <Element
          name="game_choice"
          id="game_choice"
          className="w-[320px] sm:w-[360px] h-[700px] mx-5 px-5 lg:my-8 my-12 rounded-3xl flex flex-col justify-start items-center"
        >
          <div className="flex justify-center my-5">
            <img className="object-cover mr-2" src={logoRed} alt="logo red" />
            <div>
              <h3 className="text-white text-3xl">PolarFi</h3>
              <span className="text-white text-left">Gaming</span>
            </div>
          </div>
          <p className="text-slate-500 pb-10 text-center h-[300px]">
            Explore the thrilling universe of PolarFi Games, where we are at the
            forefront of crafting cutting-edge crypto games. Immerse yourself in
            a groundbreaking platform offering multiplayer experiences and the
            opportunity to earn through play-to-earn (P2E) dynamics.
          </p>
          <MoreInfo />
          <img
            src={icon_heart}
            alt="Heart Bear"
            className="w-[150px] h-[200px] mx-auto mt-5"
          />
        </Element>
        <Element
          name="subscription_choice"
          id="subscription_choice"
          className="w-[320px] sm:w-[360px] h-[700px] mx-5 px-5 lg:my-8 my-12 rounded-3xl flex flex-col justify-start items-center"
        >
          <div className="flex justify-center my-5">
            <img
              className="object-cover mr-2"
              src={logoPurple}
              alt="logo purple"
            />
            <div>
              <h3 className="text-white text-3xl">PolarFi</h3>
              <span className="text-white text-left">Subscription</span>
            </div>
          </div>
          <p className="text-slate-500 pb-10 text-center h-[300px]">
            Your gateway to a realm of exclusive benefits and unlockable
            treasures. Elevate your experience with a comprehensive suite of
            perks, accessible only to our valued subscribers. Additionally,
            enjoy the perks of our referral platform, allowing you to share the
            wealth of benefits with others. Dive into a world where subscription
            not only enhances but transforms your journey with PolarFi.
          </p>
          <MoreInfo />
          <img
            src={icon_gift}
            alt="Gift Bear"
            className="w-[150px] h-[200px] mx-auto mt-5"
          />
        </Element>
        <Element
          name="finance_choice"
          id="finance_choice"
          className="w-[320px] sm:w-[360px] h-[700px] mx-5 px-5 lg:my-8 my-12 rounded-3xl flex flex-col justify-start items-center"
        >
          <div className="flex justify-center my-5">
            <img
              className="object-cover mr-2"
              src={logoGreen}
              alt="logo green"
            />
            <div>
              <h3 className="text-white text-3xl">PolarFi</h3>
              <span className="text-white text-left">Finance</span>
            </div>
          </div>
          <p className="text-slate-500 pb-10 text-center h-[300px]">
            Discover the limitless possibilities of PolarFi Finance, where
            earnings become an art form. Unlock your financial potential through
            NFT staking, revenue sharing, and a myriad of other innovative
            mechanisms
          </p>
          <MoreInfo />
          <img
            src={icon_fusee}
            alt="Fusee Bear"
            className="w-[150px] h-[200px] mx-auto mt-5"
          />
        </Element>
      </div>
    </section>
  );
}
