import pbbr from "../assets/img/pbbr.png";
import pff_freestyle from "../assets/img/pff_freestyle.png";
import HareGames from "../assets/img/HareGames.webp";
import { useEffect } from "react";
import { Element } from "react-scroll";

export default function Games() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const titleChoice = document.getElementById("title_game");

      if (titleChoice.getBoundingClientRect().top < viewportHeight) {
        titleChoice.classList.add("animate-fadeInUpScroll");
      }

      const textChoice = document.getElementById("text_game");

      if (textChoice.getBoundingClientRect().top < viewportHeight) {
        textChoice.classList.add("animate-fadeInUpScroll");
      }

      const gameLeft = document.getElementById("game_left");

      if (gameLeft.getBoundingClientRect().top < viewportHeight) {
        gameLeft.classList.add("animate-fadeInLeftScroll");
      }

      const gameCenter = document.getElementById("game_center");

      if (gameCenter.getBoundingClientRect().top < window.innerHeight) {
        gameCenter.classList.add("animate-fadeInUpScroll");
      }

      const gameRight = document.getElementById("game_right");

      if (gameRight.getBoundingClientRect().top < window.innerHeight) {
        gameRight.classList.add("animate-fadeInRightScroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="my-20" id="mini_game">
      <div className="text-center flex flex-col p-6 m-4">
        <Element name="title_game" id="title_game">
          <h3 className="font-bold text-white text-3xl">
            3 New Mini Games Created With Many More to Come
          </h3>
        </Element>
        <Element name="text_game" id="text_game">
          <p className="text-slate-500 pb-10 pt-3">
            Dive into the excitement of our new P2E Mini Games! Compete for big
            prizes against other players and turn your gaming skills into
            valuable rewards. Join the fun now!
          </p>
        </Element>
      </div>
      <div className="flex flex-wrap justify-center ">
        <Element name="game_left" id="game_left" className="mx-5 my-5 ">
          <div className="relative group">
            <a href="https://pff.polarfi.io/">
              <img
                className="w-96 h-72 object-cover"
                src={pff_freestyle}
                alt="PFF: PolarFi Freestyle"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <div className="text-center">
                  <p className="text-white font-bold">Play the game</p>
                  <svg
                    className="w-12 h-12 mx-auto fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <p className="text-white">PFF: PolarFi Freestyle</p>
        </Element>
        <Element name="game_center" id="game_center" className="mx-5 my-5 ">
          <div className="relative group">
            <a href="https://pbbr.polarfi.io/">
              <img
                className="w-96 h-72 object-cover"
                src={pbbr}
                alt="PolarBear Battle Royale"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <div className="text-center">
                  <p className="text-white font-bold">Play the game</p>
                  <svg
                    className="w-12 h-12 mx-auto fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <p className="text-white">PolarBear Battle Royale</p>
        </Element>
        <Element name="game_right" id="game_right" className="mx-5 my-5 ">
          <div className="relative group">
            <a href="https://haregames.polarfi.io/">
              <img
                className="w-96 h-72 object-cover"
                src={HareGames}
                alt="HareGames"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <div className="text-center">
                  <p className="text-white font-bold">Play the game</p>
                  <svg
                    className="w-12 h-12 mx-auto fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <p className="text-white">HareGames</p>
        </Element>
      </div>
    </section>
  );
}
