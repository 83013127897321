export default function CommunityButton() {
  return (
    <a
      href="https://discord.gg/polarfi"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        name="dapp_button"
        className="text-white bg-sky-500 h-10 px-5 rounded-lg transition-transform transform hover:scale-105 hover:bg-sky-600 hover:shadow-lg"
      >
        Join the community
      </button>
    </a>
  );
}
