import RoadfinanceButton from "./button/RoadfinanceButton";
import themission from "../assets/img/Themission.png";
import { useEffect } from "react";

export default function RoadFinance() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const financeVideo = document.getElementById("finance_video");

      if (financeVideo.getBoundingClientRect().top < viewportHeight) {
        financeVideo.classList.add("animate-fadeInRightScroll");
      }

      const financePhase = document.getElementById("finance_phase");

      if (financePhase.getBoundingClientRect().top < viewportHeight) {
        financePhase.classList.add("animate-fadeInLeftScroll");
      }

      const financeButton = document.getElementById("finance_button");

      if (financeButton.getBoundingClientRect().top < viewportHeight) {
        financeButton.classList.add("visible");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="flex flex-col lg:flex-row my-20 mx-4 lg:mx-32 items-center">
      <div name="finance_video" id="finance_video" className="lg:w-1/2 mx-auto">
        <img
          src={themission}
          alt="Finance"
          className="w-full max-w-[500px] h-auto mx-auto object-cover"
        />
      </div>
      <div className="text-center mt-10 lg:text-left lg:mt-0 lg:ml-10 lg:w-1/2">
        <div name="finance_phase" id="finance_phase">
          <h3 className="font-bold text-white text-3xl">
            The Beginning of PolarFi Finance - Phase 1
          </h3>
          <p className="text-slate-500 pb-10 pt-3">
            PolarFi's finance initial section offers innovative features like
            revenue sharing, NFT staking, and the exciting jackpot product
            called "Fortune." This unique combination provides users with
            diverse opportunities to engage with the platform, earning rewards
            and experiencing the thrill of the jackpot feature. The revenue
            share model enhances the overall user experience by allowing
            participants to benefit directly from the platform's success.
          </p>
        </div>
        <div id="finance_button" className=" animate-fadeIn">
          <RoadfinanceButton />
        </div>
      </div>
    </section>
  );
}
