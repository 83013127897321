import polarIcon from "../assets/img/polar_icon.png";
import twitter from "../assets/img/twitter.svg";
import discord from "../assets/img/discord.svg";
import instagram from "../assets/img/instagram.svg";
import { useEffect } from "react";
import { Element } from "react-scroll";

export default function Networks() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const community_text = document.getElementById("community_text");

      if (community_text.getBoundingClientRect().top < viewportHeight) {
        community_text.classList.add("animate-fadeInLeftScroll");
      }

      const communityImage = document.getElementById("community_image");

      if (communityImage.getBoundingClientRect().top < viewportHeight) {
        communityImage.classList.add("animate-fadeInRightScroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="flex flex-col md:flex-row items-center my-20 mx-4 lg:mx-32">
      <Element
        name="community_text"
        id="community_text"
        className="flex flex-col md:text-left text-center justify-between md:w-1/2"
      >
        <div>
          <h3 className="font-bold text-white text-3xl">Join our community</h3>
          <p className="text-slate-500 mt-5">
            Become part of our vibrant community at PolarFi! Engage with
            like-minded individuals, stay updated on the latest developments,
            and share your insights. Join the conversation, and together, let's
            explore the exciting possibilities in the world of decentralized
            finance. Connect with us to be a valuable part of the PolarFi
            journey.
          </p>
        </div>
        <div className="flex justify-center md:justify-start mt-10 md:mt-28">
          <a
            href="https://twitter.com/polarfigames"
            className="mx-2 transition-transform hover:scale-110"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={twitter}
              alt="twitter"
              className="w-8 h-8 cursor-pointer"
            />
          </a>
          <a
            href="https://discord.gg/polarfi"
            className="mx-2 transition-transform hover:scale-110"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={discord}
              alt="discord"
              className="w-8 h-8 cursor-pointer"
            />
          </a>
          <a
            href="https://www.instagram.com/polarfigames/"
            className="mx-2 transition-transform hover:scale-110"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagram}
              alt="instagram"
              className="w-8 h-8 cursor-pointer"
            />
          </a>
        </div>
      </Element>
      <Element
        name="community_image"
        id="community_image"
        className="md:w-1/2 flex justify-center relative"
      >
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-24 -right-10 w-[300px] h-[300px] z-0"
            viewBox="0 0 414 378"
            fill="none"
          >
            <path
              d="M392.673 191.926C392.673 294.692 304.77 378 196.337 378C87.9029 378 0 294.692 0 191.926C0 89.1603 87.9029 5.8522 196.337 5.8522C304.77 5.8522 473.844 -51.3507 392.673 191.926Z"
              fill="#0079BD"
            />
          </svg>
          <img
            src={polarIcon}
            alt="Polar Icon"
            className="relative left-12 bottom-8 z-10 w-[260px] h-[450px]"
          />
        </div>
      </Element>
    </section>
  );
}
