import fortune from "../assets/img/fortune.png";
import BuyticketButton from "./button/BuyticketButton";
import { useEffect } from "react";
import { Element } from "react-scroll";

export default function Fortune() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const fortuneTitle = document.getElementById("fortune_title");

      if (fortuneTitle.getBoundingClientRect().top < viewportHeight) {
        fortuneTitle.classList.add("animate-fadeInUpScroll");
      }

      const fortuneText = document.getElementById("fortune_text");

      if (fortuneText.getBoundingClientRect().top < viewportHeight) {
        fortuneText.classList.add("animate-fadeInUpScroll");
      }

      const fortuneImage = document.getElementById("fortune_image");

      if (fortuneImage.getBoundingClientRect().top < viewportHeight) {
        fortuneImage.classList.add("animate-fadeInUpScroll");
      }

      const fortuneButton = document.getElementById("fortune_button");

      if (fortuneButton.getBoundingClientRect().top < viewportHeight) {
        fortuneButton.classList.add("animate-fadeInUpScroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      className="flex flex-col items-center justify-center mx-auto px-4"
      id="finance"
    >
      <div className="text-center my-4">
        <Element name="fortune_title" id="fortune_title">
          <h3 className="font-bold text-white text-3xl">
            Earn Big with Fortune
          </h3>
        </Element>
        <Element name="fortune_text" id="fortune_text">
          <p className="text-slate-500 max-w-prose mx-auto my-6">
            PolarFi Fortune is a groundbreaking product that adds an element of
            excitement to the platform. This jackpot feature offers users the
            chance to win significant rewards, creating a dynamic and engaging
            experience. By participating in "Fortune," users not only have the
            opportunity for substantial gains but also contribute to the vibrant
            community and ecosystem within PolarFi's finance section.
          </p>
        </Element>
      </div>
      <Element name="fortune_image" id="fortune_image" className="my-4">
        <img
          src={fortune}
          alt="fortune"
          className="w-full max-w-[500px] lg:max-w-[1000px] h-auto mx-auto object-cover"
        />
      </Element>
      <Element name="fortune_button" id="fortune_button" className="my-4">
        <BuyticketButton />
      </Element>
    </section>
  );
}
