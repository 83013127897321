export default function JoinTeam() {
  return (
    <>
      <a
        href="https://discord.gg/polarfi"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button
          name="join_button"
          className="text-white bg-black bg-opacity-0 h-10 px-5 rounded-lg border border-white transition duration-300 ease-in-out hover:bg-white hover:text-black hover:border-transparent hover:shadow-outline"
        >
          Join the Team
        </button>
      </a>
    </>
  );
}
