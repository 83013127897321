import RoadgameButton from "./button/RoadgameButton";
import pff_intro from "../assets/video/pff_intro.mp4";
import { useEffect } from "react";

export default function RoadGames() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const gameVideo = document.getElementById("game_video");

      if (gameVideo.getBoundingClientRect().top < viewportHeight) {
        gameVideo.classList.add("animate-fadeInRightScroll");
      }

      const gamePhase = document.getElementById("game_phase");

      if (gamePhase.getBoundingClientRect().top < viewportHeight) {
        gamePhase.classList.add("animate-fadeInLeftScroll");
      }

      const gameButton = document.getElementById("game_button");

      if (gameButton.getBoundingClientRect().top < viewportHeight) {
        gameButton.classList.add("visible");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="flex flex-col lg:flex-row my-20 mx-4 lg:mx-32 items-center">
      <div name="game_video" id="game_video" className="lg:w-1/2 mx-auto">
        <video
          autoPlay
          loop
          muted
          className="w-full max-w-[500px] h-auto mx-auto"
        >
          <source src={pff_intro} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="text-center mt-10 lg:text-left lg:mt-0 lg:ml-10 lg:w-1/2">
        <div name="game_phase" id="game_phase">
          <h3 className="font-bold text-white text-3xl">
            The Beginning of PolarFi Game - Phase 1
          </h3>
          <p className="text-slate-500 pb-10 pt-3">
            PolarFi Games Phase 1 kicks off with a selection of engaging
            mini-games, with grand plans to expand into a vast library of 100s
            P2E Games. Stay tuned for an immersive social experience with
            friends, exciting airdrops, and much more! Get ready for endless fun
            and surprises as we continue to evolve and grow.
          </p>
        </div>
        <div id="game_button" className=" animate-fadeIn">
          <RoadgameButton />
        </div>
      </div>
    </section>
  );
}
