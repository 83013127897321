export default function BuyticketButton() {
  return (
    <>
      <a
        href="https:fortune.polarfi.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button
          name="ticket_button"
          className="text-white bg-black bg-opacity-0 h-10 px-5 rounded-lg border border-white transition duration-300 ease-in-out hover:bg-white hover:text-black hover:border-transparent hover:shadow-outline"
        >
          Buy Ticket
        </button>
      </a>
    </>
  );
}
