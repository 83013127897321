import Hero from "../components/Hero";
import Choice from "../components/Choice";
import RoadGames from "../components/RoadGames";
import Games from "../components/Games";
import Polaris from "../components/Polaris";
import PolarRPG from "../components/PolarRPG";
import Fortune from "../components/Fortune";
import RoadFinance from "../components/RoadFinance";
import Upcoming from "../components/Upcoming";
import Frost from "../components/Frost";
import Team from "../components/Team";
import Networks from "../components/Networks";
import Border from "../components/Border";

export default function Home() {
  return (
    <div>
      <Hero />
      <Choice />
      <RoadGames />
      <Games />
      <Border />
      <PolarRPG />
      <Border />
      <Polaris />
      <Border />
      <Fortune />
      <RoadFinance />
      <Upcoming />
      <Border />
      <Frost />
      <Border />
      <Team />
      <Networks />
    </div>
  );
}
